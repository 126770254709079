export const careersHeroSlider = () => {
  let intervalId;
  let isRunning = false;

  // Function to toggle the displayed class and animation class
  const toggleDisplayedClass = (parent) => {
    const pictures = parent.querySelectorAll('.RS-intro__slider-picture');
    const displayedClass = 'RS-intro__slider-picture--displayed';
    const animationClass = 'RS-looped-animation--running';
    let currentIndex = -1;

    // Find the current displayed picture
    pictures.forEach((picture, index) => {
      if (picture.classList.contains(displayedClass)) {
        currentIndex = index;
      }
    });

    // console.log(`Current index: ${currentIndex}`);

    // Remove the displayed and animation classes from the current picture
    if (currentIndex !== -1) {
      pictures[currentIndex].classList.remove(displayedClass);
      const currentOverlays = pictures[currentIndex].querySelectorAll('.RS-image-glitch-overlay');
      currentOverlays.forEach((overlay) => {
        overlay.classList.remove(animationClass);
      });
    }

    // Calculate the next index
    const nextIndex = (currentIndex + 1) % pictures.length;

    // console.log(`Next index: ${nextIndex}`);

    // Add the displayed and animation classes to the next picture
    pictures[nextIndex].classList.add(displayedClass);
    const nextOverlays = pictures[nextIndex].querySelectorAll('.RS-image-glitch-overlay');
    nextOverlays.forEach((overlay) => {
      overlay.classList.add(animationClass);
    });
  };

  // Function to start the interval
  const startInterval = () => {
    if (!isRunning) {
      const photoElements = document.querySelectorAll('.RS-intro__photo');
      const delay = 600; // Delay in milliseconds

      intervalId = setInterval(() => {
        photoElements.forEach((photoElement, index) => {
          if (window.getComputedStyle(photoElement).display !== 'none') {
            setTimeout(() => {
              // console.log(`Toggling displayed class for photo element at index ${index}`);
              toggleDisplayedClass(photoElement);
            }, delay * index);
          }
        });
      }, 7000);

      isRunning = true;
      // console.log('Starting interval');
    }
  };

  // Function to stop the interval
  const stopInterval = () => {
    clearInterval(intervalId);
    isRunning = false;
    // console.log('Clearing interval');
  };

  // Create an IntersectionObserver instance
  const observer = new IntersectionObserver((entries) => {
    let anyVisible = false;
    entries.forEach(entry => {
      if (entry.isIntersecting && window.getComputedStyle(entry.target).display !== 'none') {
        // console.log('Element is intersecting and visible');
        anyVisible = true;
      } else {
        // console.log('Element is not intersecting or not visible');
      }
    });

    if (anyVisible) {
      if (!isRunning) {
        startInterval();
      }
    } else {
      const allHidden = Array.from(document.querySelectorAll('.RS-intro__photo')).every(photoElement => window.getComputedStyle(photoElement).display === 'none');
      if (allHidden) {
        stopInterval();
      }
    }
  }, {
    root: null, // Use the viewport as the root
    threshold: 0 // Trigger when any part of the element is visible
  });

  // Select all .RS-intro__photo elements and observe them
  const photoElements = document.querySelectorAll('.RS-intro__photo');
  photoElements.forEach((photoElement) => {
    observer.observe(photoElement);
  });

  // Handle visibility change to pause/resume the interval
  document.addEventListener('visibilitychange', () => {
    if (document.hidden) {
      // console.log('Document hidden');
      stopInterval();
    } else {
      // console.log('Document visible');
      const anyVisible = Array.from(photoElements).some(photoElement => window.getComputedStyle(photoElement).display !== 'none');
      if (anyVisible && !isRunning) {
        startInterval();
      }
    }
  });

  // Initial check on page load
  const initialVisibleElement = Array.from(photoElements).find(photoElement => window.getComputedStyle(photoElement).display !== 'none');
  if (initialVisibleElement) {
    // console.log('Initial visible element found');
    startInterval();
  } else {
    // console.log('No initial visible element found');
  }
};

